import { getRandomInt } from './helpers';

const grid = document.getElementsByClassName('masonry')[0];
const hero = document.getElementsByClassName('hero')[0];

const w = Math.max(
  document.documentElement.clientWidth,
  window.innerWidth || 0
);

const imgSrcMap = [];

const vcover = 'vcover';
const hcover = 'hcover';

let device = '';

let photosCur = 0;

let photosChunkCur = 0;

let prevBreakpoint;
let breakpointId;

const options = {
  root: null,
  rootMargin: '0px',
  threshold: 0.5
};
const enterBreakpoint = function(entries, observer) {
  entries.forEach(entry => {
    const { isIntersecting } = entry;
    if (isIntersecting) {
      setGalleryPhotos(device);
      observer.unobserve(prevBreakpoint);
    }
  });
};

const observer = new IntersectionObserver(enterBreakpoint, options);

start();

function start() {
  if (w < 400) {
    device = 'small';
    createColumns(1);
    setCoverPhoto(vcover).then(() => setGalleryPhotos('small'));
    return;
  }

  if (w > 1200) {
    device = 'big';
    createColumns(3);
    setCoverPhoto(hcover).then(() => setGalleryPhotos('big'));
    return;
  }

  device = 'big';
  createColumns(2);
  setCoverPhoto(hcover).then(() => setGalleryPhotos('big'));
}

function createColumns(n) {
  for (let i = 0; i < n; i++) {
    const column = document.createElement('div');
    column.classList.add('column');
    grid.appendChild(column);
  }
}

function setGalleryPhotos(folder) {
  fetch(`./${folder}/${photosChunkCur++}.txt`)
    .then(res => res.text())
    .then(data => {
      if (data.startsWith('<!DOCTYPE html>')) {
        breakpointId = undefined;
        return;
      }

      const urls =
        folder === 'big'
          ? data.split(';').map(d => d.split('\n'))
          : data.split('\n');
      setPhotos(urls);
    })
    .catch(err => console.log(err));
}

function setCoverPhoto(type) {
  const randNum = type === vcover ? getRandomInt(100) : getRandomInt(100);

  return new Promise((resolve, reject) => {
    fetch(`./${type}/${randNum}.txt`)
      .then(res => res.text())
      .then(data => {
        let src = '';

        if (type === vcover) {
          src = data;
        } else {
          const urls = data.split('\n');
          src = device === 'small' ? urls[0] : urls[1];
        }

        const img = new Image();
        img.src = src;
        handleHeroImageLoaded();
        resolve();

        hero.style.backgroundImage = `url(${img.src})`;
      })
      .catch(err => reject(err));
  });
}

function handleHeroImageLoaded() {
  hero.onclick = e => {
    hero.classList.add('hide');
    grid.classList.add('show');
    document.body.style.overflow = 'auto';
  };
}

function setPhotos(urls) {
  const columns = document.getElementsByClassName('column');
  const breakpointInd = Math.ceil(urls.length / 2);

  urls.forEach((url, i) => {
    let img;
    if (Array.isArray(url)) {
      const [medium, large] = url;
      if (!medium || !large) return;
      img = new Image();
      img.src = medium;
      imgSrcMap.push(large);
      img.dataset.id = photosCur;
      photosCur++;
    } else {
      if (!url) return;
      img = new Image();
      img.src = url;
    }

    img.classList.add('masonry-item');
    img.setAttribute('loading', 'lazy');

    if (i === breakpointInd) {
      breakpointId = photosCur;
      prevBreakpoint = img;
      prevBreakpoint.onload = e => {
        observer.observe(prevBreakpoint);
      };
    }
    const column = columns[i % columns.length];

    column.appendChild(img);
  });
}

// VIEW
const view = document.getElementsByClassName('view')[0];
const cancel = document.getElementsByClassName('cancel')[0];

let curViewImg = 0;

cancel.addEventListener('click', e => {
  document.body.style.overflow = 'auto';
  view.classList.remove('active');
});

window.addEventListener('keyup', e => {
  if (e.keyCode === 27) {
    view.classList.remove('active');
  }
});

window.addEventListener('keydown', e => {
  if (e.keyCode === 37) {
    changeView('prev');
  } else if (e.keyCode === 39) {
    changeView('next');
  }
});

document.addEventListener('click', e => {
  if (!isMobileDevice() && e.target.classList.contains('masonry-item')) {
    curViewImg = e.target.dataset.id;
    view.classList.add('active');
    document.body.style.overflow = 'hidden';
    view.style.backgroundImage = `url(${imgSrcMap[curViewImg]})`;
  }
});

view.addEventListener('click', e => {
  if (!e.target.classList.contains('cancel')) {
    const { clientX } = e;
    if (clientX < w / 2) {
      changeView('prev');
    } else {
      changeView('next');
    }
  }
});

function changeView(dir) {
  if (dir === 'next') {
    curViewImg++;
  } else {
    curViewImg--;
  }

  curViewImg = curViewImg <= 0 ? 0 : curViewImg;
  curViewImg =
    curViewImg >= imgSrcMap.length - 1 ? imgSrcMap.length - 1 : curViewImg;

  const src = imgSrcMap[curViewImg];
  view.style.backgroundImage = `url(${src})`;

  if (curViewImg === breakpointId) {
    setGalleryPhotos('big');
  }
}

function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
}
